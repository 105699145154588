import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { signin, signup, createAccount } from "../../Redux/actions/auth";
import { useNavigate } from "react-router-dom";
import { experienceput } from "../../Redux/actions/experience";
import CssBaseline from "@mui/material/CssBaseline";
import {
  Box,
  Avatar,
  Button,
  Paper,
  Card,
  CardMedia,
  CardContent,
  Grid,
  Typography,
  Container,
  Stack,

} from "@mui/material";



import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";

import Link from "@mui/material/Link";

import { createTheme, ThemeProvider } from "@mui/material/styles";
import GlobalStyles from "@mui/material/GlobalStyles";

import { styled } from '@mui/material/styles';

import Logo from '../../assets/BrandBuddy_Logo.png'
/*
import AddressForm from "./AddressForm";
import DateForm from "./DateForm";
import VOForm from "./VOForm";
import Review from "./Review";
*/
//import GenerateQRComponent from "./GenerateQRComponent/GenerateQRComponent.js";
import Step1CreateAccount from "./Step1CreateAccount";
import Step4CheckPhone from "./Step4CheckYourPhone";
import AuthReCaptcha from "./Step1CreateAccount/AuthReCaptcha";
import Icons from "./Step2CheckYourEmail";
import SetupPayment from "./Step3SetupPayment";


const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#aec6cf',
  ...theme.typography.body2,
  padding: theme.spacing(2),
  margin: theme.spacing(2),
  height: '100%',
  textAlign: 'center',
  color: "theme.palette.text.primary,"
}));


//const steps = ["Create Account", "Check Email", "Check Phone", "Setup Payment", "Done"];
const steps = ["Create Account", "Check Email"];

function getStepContent(step, setActiveStep) {
  switch (step) {
    case 0:
      return <Step1CreateAccount />;
    case 1:
      return <Icons />;
    case 2:
      return <Step4CheckPhone onSuccess={() => setActiveStep(step + 1)} />
    case 3:
      return <SetupPayment />;
    case 4:
      return <AuthReCaptcha />;
    default:
      throw new Error("Unknown step");
  }
}

const theme = createTheme({
  root: {
    flexGrow: 1,
  },
  palette: {
    background: {
      default: "#aec6cf"
    }
  },
  spacing: [0, 2, 3, 5, 8],
});

export default function CreateAccount() {

  let userLocalStorage = localStorage.getItem("profile");
  let user;
  //const user = JSON.parse(localStorage.getItem('profile');
  if (user === null) user = null;
  else user = JSON.parse(userLocalStorage);
  {/* <Step4CheckPhone onSuccess={() => setActiveStep(activeStep + 1)} />; */ }

  const { createAccountData } = useSelector((state) => state.createAccountDataState);
  const verifyOtpStatus = useSelector((state) => state.userReducer.verifyOtpStatus);
  const [stepOneDone, setStepOneDone] = React.useState(false);
  const [activeStep, setActiveStep] = React.useState(user ? 1 : 0);
  const dispatch = useDispatch();
  const history = useNavigate();

  const [idGeneratedExperience, setIdGeneratedExperience] =
    React.useState(null);


  const handleNext = () => {
    if (activeStep == 0) dispatch(createAccount(createAccountData, history));
    setActiveStep(activeStep + 1);
console.log(activeStep)
    if(activeStep === 1)history("/client");
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  React.useEffect(() => {
    if (//createAccountData.firstName &&
      //createAccountData.lastName &&
      createAccountData.isValidateEmail &&
      //createAccountData.isAgreeTerms &&
      //createAccountData.isNews &&
      createAccountData.isPassword) {
        setStepOneDone(true)
        /*
      let myInterval = setInterval(() => {

        if (window.grecaptcha.getResponse().length > 0) {
          console.log("!!!!!!!!!!!");
          setStepOneDone(true)
          clearInterval(myInterval)
        }
      }, 500);
      */
    } else  setStepOneDone(false)
  }, [createAccountData]);

  const validateDisabled = () => {
    if (activeStep === 0) {
      return stepOneDone ? false : true;
    }

    if (activeStep === 2 && verifyOtpStatus !== 'success') {
      return true
    }

    return null;
  }
  return (

    <Stack
      sx={{ width: 1, height: "100vh" }}
    >
      <Grid container

      >

        <Grid item xl={12} lg={12} md={12} sm={12} sx={{ m: 1 }}
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justify="center"
        >
          <Card sx={{
            backgroundColor: "transparent",
            border: "none",
            boxShadow: "none",
          }}>
            <CardMedia
              component="img"
              sx={{ display: 'flex', flexDirection: 'column', width: 100, }}
              image={Logo}
              alt="Live from space album cover"
            />
          </Card>
        </Grid>

        <Grid item xl={12} lg={12} md={12} sm={12} 
                container
                spacing={0}
                direction="column"
                alignItems="center"
                justify="center">

          <Stepper activeStep={activeStep}  >
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>

        </Grid>


        {activeStep === steps.length ? (
{/*
          <Grid item xl={12} lg={12} md={12} sm={12}>
            <Typography variant="h5" gutterBottom>
              Thank you for your time.
            </Typography>
            <Typography variant="subtitle1">Your order URL is</Typography>
            <Link
              href={`${window.location.href}${idGeneratedExperience}`}
              color="inherit"
            >
              {window.location.href}{idGeneratedExperience}
            </Link>
            {/*<GenerateQRComponent />*
          </Grid>

        */
       
           }
        ) : (
          <>
            <Grid item xl={12} lg={12} md={12} sm={12} sx={{ m: 1 }}>
              {getStepContent(activeStep, setActiveStep)}
            </Grid>

            <Grid item xl={12} lg={12} md={12} sm={12} sx={{ m: 1 }}>
              {activeStep !== 0 && (
                <Button onClick={handleBack} sx={{ mt: 3, ml: 1 }}>
                  Back
                </Button>
              )}
              <Button
                variant="contained"
                onClick={handleNext}
                sx={{ mt: 3, ml: 1 }}
                disabled={validateDisabled()}
              >
                {activeStep === steps.length - 1 ? "Done" : "Next"}
              </Button>
            </Grid>
          </>
        )}
      </Grid>
    </Stack>

  );
}

import React, { useState, useEffect, useRef } from "react";
import { Button } from "@mui/material";
import { useSelector } from "react-redux";

const AudioPlay = ({ url }) => {
    const customEditData = useSelector((state) => state.customEditState.customEditData);
    const voiceUrl = useSelector((state) => state.voiceUrl);

    const [buttonName, setButtonName] = useState("Play");
    const [disabled, setDisabled] = useState(false);
    const [audioSource, setAudioSource] = useState("");

    const audioRef = useRef(null);

    // Initialize or update the audio object
    useEffect(() => {
        if (audioSource) {
            console.log("Attempting to load audio:", audioSource);
            if (audioRef.current) {
                audioRef.current.pause();
            }

            const audio = new Audio(audioSource);
            audioRef.current = audio;

            audio.onerror = () => {
                console.error("Failed to load audio. Source might be invalid:", audioSource);
            };

            audio.oncanplay = () => {
                console.log("Audio can play:", audioSource);
                setDisabled(true);
                setButtonName("Play");
            };

            audio.onended = () => {
                setButtonName("Play");
            };
        }
    }, [audioSource]);


    // Update audio source based on customEditData.idVoice
    useEffect(() => {
        if (customEditData.idVoice) {
            //setAudioSource(`https://s3.eu-west-2.amazonaws.com/brandbuddyar/${customEditData.idVoice}.mp3`);
            setAudioSource(customEditData.idVoice);

        }
    }, [customEditData.idVoice]);

    // Toggle disabled state based on voiceUrl
    useEffect(() => {
        setDisabled(voiceUrl.isOpen);
    }, [voiceUrl]);

    // Handle the play/pause button click
    const handleClick = () => {
        if (!audioRef.current) return;

        if (buttonName === "Play") {
            audioRef.current.play();
            setButtonName("Pause");
        } else {
            audioRef.current.pause();
            setButtonName("Play");
        }
    };

    return (
        <div>
            <Button
                disabled={!disabled}
                variant="contained"
                onClick={handleClick}
                color="warning"
            >
                {buttonName}
            </Button>
        </div>
    );
};

export default AudioPlay;

import { useState, lazy } from "react";
import { Routes, Route, Navigate } from "react-router-dom";

import "./App.css";
import "./index.css"

import Admin from "./Components/VoiceOutputComponent";

import Auth from "./Components/Auth/Auth";
import CreateAccount from "./Components/Auth";
import SetupPayment from "./Components/Auth/Step3SetupPayment";
import CreateCheckoutSession from "./Components/Auth/CreateCheckoutSession";
import Dashboard from "./Components/ClientMenu";
import Starter from "./Admin";

import {
  menuClose,
  menuOpen,
  menuLeftClose,
  menuLeftOpen,
  menuShOpen,
  augieVisible,
  augieHide,
} from "./Redux/actions";

import { connect } from "react-redux";

//import TutorialDataService from "./Services/tutorial.service";
import Loadable from "./Components/Loadable";

//declare const window: any;
//let idVoice: any;

const LandingPage = Loadable(lazy(() => import('./pages/landing_new/index')));

function App(props) {
  const [model1LoadedDone, setModel1LoadedDone] = useState(false);
  let userLocalStorage = localStorage.getItem("profile");
  let user;
  //const user = JSON.parse(localStorage.getItem('profile');
  if (user === null) user = null;
  else user = JSON.parse(userLocalStorage);

  return (
    <Routes>
      <Route path="/" element={<LandingPage />}></Route>
      <Route path="/admin" element={<Admin />}></Route>
      <Route path="/client" element={ <Dashboard />}></Route>
      <Route path="/auth" element={!user ? <Auth /> : <Navigate to="/client" />} />
      <Route path="/create" element={<CreateAccount />} />
      <Route path="/payment" element={<SetupPayment />} />
      <Route path="/paymentstarter" element={<CreateCheckoutSession />} />
      <Route path="/starter" element={<Starter />} />
    </Routes>
  );
}
const mapStateToProps = (state) => {
  return {
    visible: state.settingsLeft,
    audioGreeting: state.greetingAudio,
    modelAugieVisibility: state.modelAugie,
    greetingAudioFinishedProp: state.greetingAudioFinishedEvent,
    ARModeProp: state.ARMode,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    menuOpen,
    menuClose,
    menuLeftClose,
    menuLeftOpen,
    menuShOpen,
    augieVisible,
    augieHide,
    dispatch,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);

import { AUTH ,VERIFY_OPT_SUCCESS,VERIFY_OPT_FAILURE,VERIFY_OPT_REQUEST,RESEND_OTP_FAILURE,RESEND_OTP_SUCCESS,RESEND_OTP_REQUEST,EMAIL_EXISTS} from '../constants/actionTypes';
import * as api from '../../api/index.js';
import {checkEmailExist} from '../../api/index';
import { getPlanPaymentLink } from './plan';

export const signin = (formData, priceId, router) => async (dispatch) => {
  try {
    const { data } = await api.signIn(formData);

    dispatch({ type: AUTH, data });
    console.log(priceId)
    if (priceId) {
      dispatch(getPlanPaymentLink(priceId))
    } else {
      router('/client');
    }

  } catch (error) {
    console.log(error);
  }
};

export const signup = (formData, router) => async (dispatch) => {
  try {
    const { data } = await api.signUp(formData);

    dispatch({ type: AUTH, data });

    router('/');
  } catch (error) {
    console.log(error);
  }
};

export const signupfree = (formData, router) => async (dispatch) => {
  try {
    const { data } = await api.signUpFree(formData);

    dispatch({ type: AUTH, data });

    router('/client');
  } catch (error) {
    console.log(error);
  }
};


export const createAccount = (formData, router) => async (dispatch) => {
  try {
    const { data } = await api.signUp(formData);
    dispatch({ type: AUTH, data });

    //router('/');
  } catch (error) {
    console.log(error);
  }
};


export const verifyOpt = (formData) => async (dispatch) => {
  try {
    dispatch({ type: VERIFY_OPT_REQUEST });
    const { data } = await api.verfiyOpt(formData);
    dispatch({ type: VERIFY_OPT_SUCCESS, payload: data });

    // Dispatch an action with the success message
    dispatch({ type: VERIFY_OPT_SUCCESS, payload: "OTP verification successful" });

    // Clear success message after 3 seconds
    setTimeout(() => {
      dispatch({ type: VERIFY_OPT_SUCCESS, payload: "" });
    }, 3000);
  } catch (error) {
    // Dispatch an action with the error message
    dispatch({ type: VERIFY_OPT_FAILURE, payload: error.response.data });

    // Clear error message after 3 seconds
    setTimeout(() => {
      dispatch({ type: VERIFY_OPT_FAILURE, payload: "" });
    }, 3000);
  } 
}








export const resendOTP = (formData) => async (dispatch) => {
  try {
      // Dispatch an action to indicate that resend OTP request has started
      dispatch({ type: RESEND_OTP_REQUEST });

      // Make a POST request to resend OTP API with phone number in the body
      const { data } = await api.resendOTP(formData);

      // Dispatch an action with the response data
      dispatch({ type: RESEND_OTP_SUCCESS, payload: data });
  } catch (error) {
      // Dispatch an action with the error message if the request fails
      dispatch({ type: RESEND_OTP_FAILURE, payload: error.message });
  }
};


export const checkEmailExists = (formdata) => async (dispatch) => {
  try {

    const { data } = await checkEmailExist(formdata); // Assuming this API call exists
    console.log('datas',data)
    dispatch({ type:EMAIL_EXISTS, exists: data.message });
    return data;
  } catch (error) {
    console.error("Error checking email existence:", error);
    throw error;
  }
};
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CREATE_ACCOUNT_DATA } from "../../../Redux/constants/actionTypes";

import { Grid } from "@mui/material";

import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'

import { useNavigate } from "react-router-dom";

import ResponsiveFontSizes from "./ResponsiveFontSizes";

import { checkEmailExists } from "../../../Redux/actions/auth";
import Article from "./Article";

import Input from "./Input";

import * as actionType from "../../../Redux/constants/actionTypes";


//Custom components

import AuthRadioButtons from "../AuthRadioButtons";
import AuthReCaptcha from "./AuthReCaptcha";


const Step1CreateAccount = () => {
  const { createAccountData, emailExistMessage } = useSelector((state) => state.createAccountDataState)
  const [form, setForm] = useState(createAccountData);
  const [passwordHelperText, setPasswordHelperText] = useState("")
  const [passwordHelperText2, setPasswordHelperText2] = useState("")
  const [passwordState, setPasswordState] = useState("")
  const [confirmPasswordState, setConfirmPasswordState] = useState("")
  const [emailHelperText, setEmailHelperText] = useState("")
  const [emailExists, setEmailExists] = useState(false);
  const dispatch = useDispatch();
  const history = useNavigate();

  const [showPassword, setShowPassword] = useState(false);
  const handleShowPassword = () => setShowPassword(!showPassword);




  const validateEmail = (email) => {
    const regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

    // Use the test() method to check if the email matches the regular expression
    return regex.test(email);
  };

  const handleChange = (e) => {
    if (e.target.name == "password") {
      setPasswordState(e.target.value); setForm({ ...createAccountData, [e.target.name]: e.target.value });
    }
    if (e.target.name == "confirmPassword") {
      setConfirmPasswordState(e.target.value); setForm({ ...createAccountData, [e.target.name]: e.target.value });
    }


    if (e.target.name == "email") {
      if (e.target.value.length === 0) {
        setEmailHelperText(""); // Clear email helper text
        setEmailExists(false); // Hide email exists message
      } else if (validateEmail(e.target.value)) {
        setEmailHelperText("Valid email address");
        setEmailExists(false);
        setForm({ ...createAccountData, ["isValidateEmail"]: true, [e.target.name]: e.target.value });
      } else {
        setEmailHelperText("Invalid email address");
        setEmailExists(false);
        setForm({ ...createAccountData, ["isValidateEmail"]: false, [e.target.name]: e.target.value });
      }

      try {
        const formData = {
          email: e.target.value,
        };
        dispatch(checkEmailExists(formData));
      } catch (error) {
        console.error("Error checking email existence:", error);
        setEmailHelperText("Error checking email existence");
        setEmailExists(false);
      }
    } else if (e.target.name != "password" && e.target.name != "confirmPassword") setForm({ ...createAccountData, [e.target.name]: e.target.value });

  }

  const handlePhoneChange = (value) => {
    setForm({ ...createAccountData, ["phoneNumber"]: value });
    dispatch({ type: actionType.CREATE_ACCOUNT_DATA, data: { ...createAccountData, ["phoneNumber"]: value } })
  };

  React.useEffect(() => {

    dispatch({ type: CREATE_ACCOUNT_DATA, data: form });
    console.log(form)
  }, [form]);

  React.useEffect(() => {


  }, [createAccountData]);


  React.useEffect(() => {

    console.log('Password ', passwordState)
    console.log('ConfirmPassword ', confirmPasswordState)

    if (passwordState.length < 8) setPasswordHelperText("Minimum 8 characters"); else if (passwordState.length >= 8) setPasswordHelperText("Ok")


    if (passwordState != confirmPasswordState) {

      setForm({ ...createAccountData, ["isPassword"]: false });
      setPasswordHelperText2("The passwords do not match")
    }
    else if (passwordState === confirmPasswordState && !!passwordState && !!confirmPasswordState) {

      setForm({ ...createAccountData, ["isPassword"]: true });
      setPasswordHelperText2("Ok")
    };

  }, [passwordState, confirmPasswordState]);
  return (

    <Grid container sx={{ p: 2 }}
      spacing={0}>
      {/*
      <Grid item md={6} sm={12} xs={12} sx={{ p: 2 }}>
        
        <Input
          name="firstName"
          label="First Name"
          handleChange={handleChange}
          autoFocus
          defaultValue={form.firstName}

        />
      </Grid>
      <Grid item md={6} sm={12} xs={12} sx={{ p: 2 }}>
        <Input
          name="lastName"
          label="Last Name"
          handleChange={handleChange}
          defaultValue={form.lastName}
        />
      </Grid>
      */}
      <Grid item md={12} sm={12} xs={12} sx={{ p: 2 }}>
        <Input
          name="email"
          label="Email Address"
          handleChange={handleChange}
          type="email"
          defaultValue={form.email}
          helperText={emailHelperText}
        />

        {/*  emailExistMessage && (
            <p style={{ color: 'red', margin: 0, fontSize: '12px', paddingLeft: '14px' }}>
              Email already in use
            </p>
          )*/}
      </Grid>
      <Grid item md={6} sm={12} xs={12} sx={{ p: 2 }}>
        <Input
          name="password"
          label="Password"
          handleChange={handleChange}
          type={showPassword ? "text" : "password"}
          handleShowPassword={handleShowPassword}
          helperText={passwordHelperText}
          defaultValue={form.password}
        />
      </Grid>
      <Grid item md={6} sm={12} xs={12} sx={{ p: 2 }}>
        <Input
          name="confirmPassword"
          label="Confirm Password"
          handleChange={handleChange}
          type="password"
          helperText={passwordHelperText2}

          defaultValue={form.confirmPassword}
        />
      </Grid>
      <Grid item md={12} sm={12} xs={12} sx={{ p: 2 }}>
        <ResponsiveFontSizes />
      </Grid>
      {/*
      <Grid item md={12} sm={12} xs={12}
        container
        direction="column"
        alignItems="center"
        justifyContent="center"
      >
        <PhoneInput
          name="phoneNumber"
          country="US"
          value={form.phoneNumber}
          onChange={handlePhoneChange}
          placeholder="Enter phone number"
          style={{
            // Your custom styles for the input field

            borderRadius: "10px",
            padding: "20px",
            fontSize: "16px",
            width: "90%",
            fontFamily: "Roboto, Helvetica, Arial, sans-serif",


          }}

          inputStyle={{
            // Your custom styles for the input field
            border: "none", // Remove border
            borderBottom: "1px solid #ccc", // Only bottom border
            padding: "10px",
            fontSize: "16px",
            width: "100%",
            boxSizing: "border-box",
            marginTop: '25px',
            marginLeft: '12px'
          }}

        />
      </Grid>

      <Grid item md={12} sm={12} xs={12} sx={{ p: 2 }}>
        <AuthRadioButtons />
      </Grid>
 
      <Grid item md={12} sm={12} xs={12} sx={{ p: 2 }}>
        {createAccountData.firstName && createAccountData.lastName && createAccountData.isValidateEmail && createAccountData.isPassword && createAccountData.isAgreeTerms && createAccountData.isNews ? <AuthReCaptcha /> : null}
      </Grid>


      <Grid item lg={2} md={2} container sx={{ p: 4 }}>
        <ResponsiveFontSizes />
      </Grid>
   
      <Grid item lg={4} md={4} container sx={{ p: 4 }}>
        <Article />
      </Grid>
      */}
    </Grid>
  );
};

export default Step1CreateAccount;

import { PLANS_GET, PLANS_PAYMENT_LINK_GET } from '../constants/actionTypes';
import * as api from "../../api/index.js";

export const textToSpeechAction = (formData) => async (dispatch) => {
  try {

    console.log(formData)
    const { data } = await api.textToSpeechRequest({ body: formData });
    console.log(data)
    //dispatch({ type: 'VOICE_URL_RECEIVED', payload: data.result.SynthesisTask.TaskId });
    dispatch({ type: 'VOICE_URL_RECEIVED', payload: data.outputUri });
    
  } catch (error) {
    console.log(error);
  }
};

